import React from "react"
import { Box, Heading, Paragraph } from "grommet"


import Seo from "../components/seo"
import Layout from "../components/layout"

export default function SignUpPage() {
  return (
    <Layout>
      <Seo title="404" />
      <Box>
        <Box width="large" alignSelf="center" align="start">
          <Heading
            className="hero"
            margin={{ top: "xlarge", bottom: "xsmall" }}
            level="2"
            fill
            size="xlarge"
          >
            404{" "}
          </Heading>
          <Paragraph margin={{ bottom: "large" }} size="xlarge"></Paragraph>
        </Box>
      </Box>
    </Layout>
  )
}
